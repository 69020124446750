
/**
 * List of Walls with its characteristics
 */
const wallsList=[
    { 
        wallID: 0,
        name: "Pared 1",
        center: { x: 3.05, z: -4.0808 },
        rotation: 0
    },
    { 
        wallID: 1,
        name: "Pared 2",
        center: {x: 3.7769919170018293, z: -4.78},
        rotation: 90
    },
    { 
        wallID: 2,
        name: "Pared 3",
        center: { x: 4.9, z: -5.52 },
        rotation: 0
    },
    { 
        wallID: 3,
        name: "Pared 4",
        center: { x: 6.23, z: -6.53},
        rotation: 90
    },
    { 
        wallID: 4,
        name: "Pared 5",
        center: {x: 6.73, z: -7.59},
        rotation: 0
    },
    { 
        wallID: 5,
        name: "Pared 6",
        center:     { x: 10.8908, z: -7.19 },   // Start  {z:-7.19, x: 7:20} End {z:-7.19, 14.5}
        limit_start:{ x:  7.2369, z:-7.19 },
        limit_end:  { x: 14.5447, z:-7.19 },
        rotation: 0 
    },
    { 
        wallID: 6,
        name: "Pared 7",
        center:     { x: 14.57, z: -4.475 }, // Start  {z:-7.19, x: 14.57} End {z:-1.61, 14.5}
        limit_start:{ x: 14.57, z: -7.15 },
        limit_end:  { x: 14.57, z: -1.8 },
        rotation: 90
    },
    {  // ? 1st COLUMN WALL STARTED FROM THE RIGHT SIDE
        wallID: 7,
        name: "Pared 8",
        center:     { x: 11.345,    z:-0.36}, 
        limit_start:{ x: 11.8525, z:-0.36 },
        limit_end:  { x: 10.7800, z:-0.36 },
        rotation: 0
    },
    { // ? 2nd COLUMN WALL STARTED FROM THE RIGHT SIDE
        wallID: 8,
        name: "Pared 9",
        center:     { x: 7.5579, z:-0.36 }, 
        limit_start:{ x: 8.1158, z:-0.36 },
        limit_end:  { x: 7.0000, z:-0.36 },
        rotation: 0
    },
    { // ? 3nd COLUMN WALL STARTED FROM THE RIGHT SIDE
        wallID: 9,
        name: "Pared 10",
        center:     { x: 3.7766, z:-0.36 },
        limit_start:{ x: 4.3459, z:-0.36 },
        limit_end:  { x: 3.2074, z:-0.36 },
        rotation: 0
    },
    { // ? Panel Lateral 1A esta en el fronte
        wallID: 10,
        name: "Panel Lateral 1A",
        center:     { x: 6.8382, z: -1.0016 },   // DONE
        limit_start:{ x: 6.7506, z: -1.5723 },
        limit_end:  { x: 6.9562, z: -0.4213 },
        rotation: 100
    },
    { //? Panel Lateral 1B esta en el fronte
        wallID: 11,
        name: "Panel Lateral 1B",
        center:     { x: 6.9166, z: -0.96668 },   // DONE
        limit_start:{ x: 6.9915, z: -0.5226 }, // todo Validar 
        limit_end:  { x: 6.9213, z: -0.9227 }, // todo Validar
        rotation: 100
    },
    { //? Panel Lateral 2A esta en el fronte
        wallID: 12,
        name: "Panel Lateral 2A",
        center: { x: 10.6396, z: -1.0180}, //! Missing the Start and End
        rotation: 100
    },
    { //? Panel Lateral 2B esta en el fronte
        wallID: 13,
        name: "Panel Lateral 2B",
        center: { x: 10.6955, z: -0.9613}, //! Missing the Start and End
        rotation: 100
    },
    { //? Panel Central 1A
        wallID: 14,
        name: "Panel Central 1A",
        center:  { x:9.3686, z:-3.8372 },  //! Missing the Start and End
        rotation: -35
    },
    { //? Panel Central 1B
        wallID: 15,
        name: "Panel Central 1B",
        center: { x: 9.4415, z: -3.8539 }, //! Missing the Start and End
        rotation: -35
    },
    { //? Panel Central 2A
        wallID: 16,
        name: "Panel Central 2A",
        center: { x:10.8534,  z:-3.83 }, //! Missing the Start and End
        rotation: 35
    },
    { //? Panel Central 2B
        wallID: 17,
        name: "Panel Central 2B",
        center: { x: 10.8034, z: -3.84}, //! Missing the Start and End
        rotation: 35
    },
    { //? Panel Central 3A
        wallID: 18,
        name: "Panel Central 3A",
        center: { x:12.2146, z:-3.8436}, //! Missing the Start and End
        rotation: -35
    },
    { //? Panel Central 3B
        wallID: 19,
        name: "Panel Central 3B",
        center: { x: 12.2835, z: -3.8670 }, //! Missing the Start and End
        rotation: -35
    },
    { //? Column A
        wallID: 20,
        name: "Columna A",
        center: {x: 7.2609, z: -3.9}, //! Missing the Start and End
        rotation: 90
    },
    { //? Column B
        wallID: 21,
        name: "Columna B",
        center: {x: 7.6853, z: -3.8836 } , //! Missing the Start and End
        rotation: 90
    }
];


/**
 * List of type of techniques
 * - the name_es attribute contains the spanish term
 * - the name_en attribute contains the english term 
 */
const TECHNIQUES_LIST = [
    {id:0,  name_es: 'Óleo',              name_en: 'Oil painting'},
    {id:1,  name_es: 'Acrílico',          name_en: 'Acrylic'},
    {id:2,  name_es: 'T.Mixta'	,         name_en: 'Mixed T.'},
    {id:3,  name_es: 'Acuarela',          name_en: 'Watercolor'},
    {id:4,  name_es: 'Pastel',            name_en: 'Pastel'},
    {id:5,  name_es: 'Cera',              name_en: 'Wax'},
    {id:6,  name_es: 'Litografia',        name_en: 'Lithography'},
    {id:7,  name_es: 'Bronce',            name_en: 'Bronze'},
    {id:8,  name_es: 'Abstracto',         name_en: 'Abstract'},
    {id:9,  name_es: 'Fotografia',        name_en: 'Photography'},
    {id:10, name_es: 'Aluminio',          name_en: 'Aluminium'},
    {id:11, name_es: 'Resina',            name_en: 'Resin'},
    {id:12, name_es: 'Tintas de Alcohol', name_en: 'Alcohol Inks'},
    {id:13, name_es: 'Tinta Esmalte',     name_en: 'Enamel Ink'}
];


/** List of type of Materials
 * - the name_es attribute contains the spanish term
 * - the name_en attribute contains the english term 
 */
const MATERIALS_LIST = [
    {id:0, name_es: 'Lienzo',      name_en: 'Canvas'},
    {id:1, name_es: 'Tabla',       name_en: 'Table'},
    {id:2, name_es: 'Papel',       name_en: 'Paper'},
    {id:3, name_es: 'Tejido',      name_en: 'Fabric'},
    {id:4, name_es: 'Madera',      name_en: 'Wood'},
    {id:5, name_es: 'Resina',      name_en: 'Resin'},
    {id:6, name_es: 'Aluminio',    name_en: 'Aluminium'},
    {id:7, name_es: 'Bronce',      name_en: 'Bronze'},
    {id:8, name_es: 'Metacrilato', name_en: 'Methacrylate'} 
];


// Export the constants 
export default {wallsList, TECHNIQUES_LIST, MATERIALS_LIST};