import React from 'react'
import { Link } from 'react-router-dom'

export default function NoFoundPage() {
    return (
        <div>
            Que dices? 
            <Link to="/">Regresa a casa</Link>
        </div>
    )
}
