import {
	EventDispatcher,
	MathUtils,
	Spherical,
	Vector3
} from 'three';

const _lookDirection = new Vector3();
const _spherical = new Spherical();
const _target = new Vector3();
let _initialCameraHeight = 1.7;
const _changeEvent = { type: 'change' };
class WalkControls extends EventDispatcher  {

	constructor( object, domElement ) {
		
		super();
		
		if ( domElement === undefined ) {

			console.warn( 'THREE.WalkControls: The second parameter "domElement" is now mandatory.' );
			domElement = document;

		}

		this.object = object;
		this.domElement = domElement;
		this.target = _target;

		

		// API

		this.enabled = true;

		this.movementSpeed = 1.0;
		this.lookSpeed = 0.005;

		this.lookVertical = true;
		this.autoForward = false;

		this.activeLook = true;
		this.isMouseInside = false; // This to lock the rotations when the mouse
									// is outside the context. 

		this.heightSpeed = false;
		this.heightCoef = 1.0;
		this.heightMin = 0.0;
		this.heightMax = 1.0;

		this.constrainVertical = false;
		this.verticalMin = 0;
		this.verticalMax = Math.PI;

		this.mouseDragOn = false;

		this.mouseWalkEnabled = false;

		// internals

		this.autoSpeedFactor = 0.0;

		this.mouseX = 0;
		this.mouseY = 0;

		this.moveForward  = false;
		this.moveBackward = false;
		this.moveLeft     = false;
		this.moveRight    = false;

		this.viewHalfX = 0;
		this.viewHalfY = 0;

		// private variables

		let lat = 0;
		let lon = 0;


		// Get the Initial Camera Height
		_initialCameraHeight = this.object.position.y;

		// Get Previous Position of the Camera
		this.prevCameraPosition = object.position.clone();



		/**
		 * 
		 */
		this.handleResize = function () {

			if ( this.domElement === document ) {

				this.viewHalfX = window.innerWidth / 2;
				this.viewHalfY = window.innerHeight / 2;

			} else {

				this.viewHalfX = this.domElement.offsetWidth / 2;
				this.viewHalfY = this.domElement.offsetHeight / 2;

			}

		};

		/**
		 * 
		 * @param {*} event 
		 */
		this.onMouseDown = function ( event ) {

			if ( this.domElement !== document ) {

				this.domElement.focus();

			}

			event.preventDefault();

			// // Check if the Mouse is inside
			// if(!this.isMouseInside){
			// 	this.mouseDragOn = true;
			// 	return;
			// }

			//Check if the controller is active
			if ( this.activeLook ) {

				if( !this.mouseWalkEnabled ){
					this.mouseDragOn = true;
					return;
				}
	
				switch ( event.button ) {

					case 0: this.moveForward = true; break;
					case 2: this.moveBackward = true; break;

				}

			}

			this.mouseDragOn = true;

		};

		/**
		 * 
		 * @param {*} event 
		 */
		this.onMouseUp = function ( event ) {

			event.preventDefault();

			if ( this.activeLook ) {

				switch ( event.button ) {

					case 0: this.moveForward = false; break;
					case 2: this.moveBackward = false; break;

				}

			}

			this.mouseDragOn = false;
			
		};


		/**
		 * 
		 * @param {*} event 
		 */
		this.onMouseMove = function ( event ) {

			if ( this.domElement === document ) {

				this.mouseX = event.pageX - this.viewHalfX;
				this.mouseY = event.pageY - this.viewHalfY;

			} else {

				this.mouseX = event.pageX - this.domElement.offsetLeft - this.viewHalfX;
				this.mouseY = event.pageY - this.domElement.offsetTop - this.viewHalfY;

			}

		};

		/**
		 * 
		 * @param {*} event 
		 */
		this.onKeyDown = function ( event ) {

			//event.preventDefault();

			// Sanity Check: Check if mouse is inside or no into the context
			if ( ! this.isMouseInside ) {
				return;
			}

			// Sanity Check: check if the rotation is actived
			if(!this.activeLook)
				return;	

			switch ( event.code ) {

				case 'ArrowUp':
				case 'KeyW': this.moveForward = true; break;

				case 'ArrowLeft':
				case 'KeyA': this.moveLeft = true; break;

				case 'ArrowDown':
				case 'KeyS': this.moveBackward = true; break;

				case 'ArrowRight':
				case 'KeyD': this.moveRight = true; break;

				// case 'KeyR': this.moveUp = true; break;
				// case 'KeyF': this.moveDown = true; break;

				case 'KeyQ': 
					lon++;
				 	break;
				case 'KeyE': 
					lon--;
					break;
			}

		};


		/**
		 * 
		 * @param {*} event 
		 */
		this.onKeyUp = function ( event ) {

			switch ( event.code ) {

				case 'ArrowUp':
				case 'KeyW': this.moveForward = false; break;

				case 'ArrowLeft':
				case 'KeyA': this.moveLeft = false; break;

				case 'ArrowDown':
				case 'KeyS': this.moveBackward = false; break;

				case 'ArrowRight':
				case 'KeyD': this.moveRight = false; break;

				// case 'KeyR': this.moveUp = false; break;
				// case 'KeyF': this.moveDown = false; break;

			}

		};


		/**
		 * 
		 * @param {*} x 
		 * @param {*} y 
		 * @param {*} z 
		 * @returns 
		 */
		this.lookAt = function ( x, y, z ) {

			if ( x.isVector3 ) {

				_target.copy( x );

			} else {

				_target.set( x, y, z );

			}

			this.object.lookAt( _target );

			setOrientation( this );

			return this;

		};


		/**
		 * 
		 */
		this.update = function () {

			const targetPosition = new Vector3();

			return function update( delta ) {

				if ( this.enabled === false ) return;

				// Backup Current Camera Position
				this.prevCameraPosition.setX(this.object.position.x);
				this.prevCameraPosition.setZ(this.object.position.z);

				if ( this.heightSpeed ) {

					const y = MathUtils.clamp( this.object.position.y, this.heightMin, this.heightMax );
					const heightDelta = y - this.heightMin;

					this.autoSpeedFactor = delta * ( heightDelta * this.heightCoef );

				} else {

					this.autoSpeedFactor = 0.0;

				}

				const actualMoveSpeed = delta * this.movementSpeed;

				if ( this.moveForward || ( this.autoForward && ! this.moveBackward ) ) 
					this.object.translateZ( - ( actualMoveSpeed + this.autoSpeedFactor ) );

				if ( this.moveBackward ) 
					this.object.translateZ( actualMoveSpeed );

				if ( this.moveLeft ) 
					this.object.translateX( - actualMoveSpeed );

				if ( this.moveRight ) 
					this.object.translateX( actualMoveSpeed );

				// Set the Camera's Height to lock it to the given height
				this.object.position.y = _initialCameraHeight;

				let actualLookSpeed = delta * this.lookSpeed;

				// Lock the controler when it is not enabled
				if ( ! this.activeLook ) {
					actualLookSpeed = 0;
				}

				// Lock the controler when the mouse is not inside
				if ( ! this.isMouseInside ) {
					actualLookSpeed = 0;
				}


				let verticalLookRatio = 1;

				if ( this.constrainVertical ) {

					verticalLookRatio = Math.PI / ( this.verticalMax - this.verticalMin );

				}


				// Debug
				//console.log("Mouse X: " + this.mouseX + "; LookSpeed: " + actualLookSpeed );
				let clampedMouseX = MathUtils.clamp(this.mouseX, -100, 100) * 1.45;
				lon -= clampedMouseX * actualLookSpeed;

				//? This is the original statement, I overrided with the statement 
				//? above to limit the movement and the speed. 
				//lon -= this.mouseX * actualLookSpeed;
				if ( this.lookVertical ) lat -= this.mouseY * actualLookSpeed * verticalLookRatio;

				lat = Math.max( - 85, Math.min( 85, lat ) );

				let phi = MathUtils.degToRad( 90 - lat );
				const theta = MathUtils.degToRad( lon );

				if ( this.constrainVertical ) {

					phi = MathUtils.mapLinear( phi, 0, Math.PI, this.verticalMin, this.verticalMax );

				}

				const position = this.object.position;

				targetPosition.setFromSphericalCoords( 1, phi, theta ).add( position );

				this.object.lookAt( targetPosition );
				this.target = targetPosition;

				// TODO Improve this and only call it when there has been a 
				// change in the control 
				this.dispatchEvent( _changeEvent );

			};

		}();


		/**
		 * 
		 */
		this.dispose = function () {

			this.domElement.removeEventListener( 'contextmenu', contextmenu );
			this.domElement.removeEventListener( 'mousedown', _onMouseDown );
			this.domElement.removeEventListener( 'mousemove', _onMouseMove );
			this.domElement.removeEventListener( 'mouseup', _onMouseUp );

			window.removeEventListener( 'keydown', _onKeyDown );
			window.removeEventListener( 'keyup', _onKeyUp );

		};


		const _onMouseMove = this.onMouseMove.bind( this );
		const _onMouseDown = this.onMouseDown.bind( this );
		const _onMouseUp   = this.onMouseUp.bind( this );
		const _onKeyDown   = this.onKeyDown.bind( this );
		const _onKeyUp     = this.onKeyUp.bind( this );

		this.domElement.addEventListener( 'contextmenu', contextmenu );
		this.domElement.addEventListener( 'mousemove', _onMouseMove );
		this.domElement.addEventListener( 'mousedown', _onMouseDown );
		this.domElement.addEventListener( 'mouseup', _onMouseUp );

		window.addEventListener( 'keydown', _onKeyDown );
		window.addEventListener( 'keyup', _onKeyUp );


		// ! Testing to block the mouse when it get out of the context
		this.domElement.addEventListener('mouseleave', ()=> { this.isMouseInside = false; } );
		this.domElement.addEventListener('mouseenter', ()=> { this.isMouseInside = true;  } );

		/**
		 * 
		 * @param {*} controls 
		 */
		function setOrientation( controls ) {

			const quaternion = controls.object.quaternion;

			_lookDirection.set( 0, 0, - 1 ).applyQuaternion( quaternion );
			_spherical.setFromVector3( _lookDirection );

			lat = 90 - MathUtils.radToDeg( _spherical.phi );
			lon = MathUtils.radToDeg( _spherical.theta );

		}

		this.handleResize();

		setOrientation( this );

	}

}

function contextmenu( event ) {

	event.preventDefault();

}

export { WalkControls };