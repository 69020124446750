import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID,
};

// const firebaseConfig = {
//     apiKey: "AIzaSyAj3IdnaN6-4jNWBF0jof7vI-d91OBZ0is",
//     authDomain: "virtualgallery-lanzarote.firebaseapp.com",
//     projectId: "virtualgallery-lanzarote",
//     storageBucket: "virtualgallery-lanzarote.appspot.com",
//     messagingSenderId: "1096595466584",
//     appId: "1:1096595466584:web:20120f2bac3560296e5d2d"
// };


firebase.initializeApp(firebaseConfig);

export default firebase;