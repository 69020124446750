    import React, { Component } from 'react'
    import threeBinder from "./ThreeBinder"
    import { CircularProgress } from '@material-ui/core';
    import {Button, IconButton} from '@material-ui/core';
    import StorefrontIcon from '@material-ui/icons/Storefront';
    import CancelIcon from '@material-ui/icons/Cancel';

    export default class ThreeContainer extends Component {

        constructor(props) {
            super(props);
            const { exhibit } = this.props;
        }
  

        componentDidMount() {
           this.test = threeBinder(this.threeRootElement, this.props);
           
        }

        componentWillUnmount(){
            console.log("Element has been disposaled 2")
            console.log(this.test);
        }

        render() {
    
            return (
                <div ref={element => this.threeRootElement = element}  className="containerViewportSize centerHorizontally" >
                    
                    {/*---- Video Container -----*/}
                    <video id="videoTV" loop crossOrigin="anonymous" playsInline hidden></video>

                    {/*---- Block Container -----*/}
                    <div id="blocker" className="containerViewportSize" > 

                        {/*---- Loading Container -----*/}
                        <div id="divLoading" className="centerContent containerViewportSize OverZ">
                            <CircularProgress id="iconLoading" disableShrink style={{'color': 'white'}}/>
                            <h1>loading....</h1> 
                        </div>

                      

                        {/*---- Navigation Instructions Container -----*/}
                        <div id="divInstructions"  className="containerViewportSize OverZ" hidden >
                                <br/><br/> 
                                <span>Click para Empezar</span>
                                <br/><br/>
                                <img src="/assets/media/navegation.png" alt="Controls" width="400" />
                                <br/><br />
                                Usa las teclas <b>W A S D</b> para desplazarte<br/>
                                Usa el raton para cambiar la dirección
                        </div> 

                    </div>

                    {/*---- Navigation Instructions Container -----*/}
                    <div id="InfoPanel" name="InfoPanel">
                        
                        <div id="CanvasInfo" className="HidePanel" name="CanvasInfo">

                            <IconButton aria-label="close" id="btnClose" name="btnClose" style={{'float': 'right', 'padding': '2px'}} > 
                                <CancelIcon />
                            </IconButton>

                            <div id="ContentPanel" name="ContentPanel">
                                <span className="title">Mi Titulo</span> 
                                <span className="author">por JJ Benitez</span>
                                <p className="desciption">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in sem scelerisque, 
                                semper sem in, vehicula libero. 
                                Donec volutpat diam at enim ultricies semper. Donec a ornare dui, ac mollis dolor.
                                </p>
                                <span className="size"><b>Tamaño: </b> 160 x 20cm</span>
                                <span className="type"><b>Tipo: </b> oleos</span>
                                <span className="price"><b>Precio:</b> 300€</span>
                                <Button href="https://lanzaroteartgallery.com/" target="_blank" 
                                    type="button" size="medium"  
                                    variant="contained" className="btnShop"
                                    startIcon={<StorefrontIcon />} >  Comprar </Button>
                            </div>

                        </div>

                    </div>

                </div>
            )
        }
    }
    