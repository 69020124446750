import logo from './logo.png';
import './App.css';
import React, {useState, useRef, useEffect} from 'react';
import ExhibitionsList from './ExhibitionsList';
import uuidv4 from 'uuid/v4';

import firebase from './firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore';
import ExhibitionItem from './ExhibitionItem';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import ExhibitionTour from './ExhibitionTour';
import NoFoundPage from './NoFoundPage';
import AppBreadcrumbs from './AppBreadcrumbs';
import { Container } from '@material-ui/core';

function App() {

  const [exhbitions, setExhibitions] = useState([]);
  const [loading, setLoading]        = useState(false)
  const todoNameRef = useRef();

  const pExhibitions = firebase.firestore()
                               .collection('Exhibitions')
                               .where("active", "==", true).orderBy("dateStart", "asc");
  // Debug 
  // console.log(pExhibitions)

  function getExibitions(){
    setLoading(true);
    pExhibitions.onSnapshot((query) => {
      const items = [];
      query.forEach( (doc) => {
        items.push({id: doc.id, ...doc.data()});

        // DEBUG: 
        // console.log({id: doc.id, ... doc.data()});

      } )
      setExhibitions(items);
      setLoading(false);
      // Debug
      // console.log(items);
    });
   
  }

  useEffect(()=> {
    getExibitions();
  }, [])

  function handleAddTodo(e){
    const name = todoNameRef.current.value;
    if(name === '') return;
    setExhibitions(prevExhibitions => {
      return [...prevExhibitions, {id: uuidv4(), name: name, complete: false}]
    })
    todoNameRef.current.value = null;
  }

  function toggleItem(id){
    const newItems = [...exhbitions];
    const item = newItems.find(item => item.id === id);
    item.complete = !item.complete;
    setExhibitions(newItems);
  }

  function handleClearItems(){
    const newItems = exhbitions.filter(item => !item.complete);
    setExhibitions(newItems);
  }

  return (
    <div className="App">

      <header className="App-header" hidden>
        <img src={logo} className="App-logo" alt="logo" /> 
      </header>

      <BrowserRouter>

      <AppBreadcrumbs exhibitions = {exhbitions} />

      {/* <section> */}

          <Switch>
            <Route path="/" exact>  
              {loading ? 
                (  <div className="centerContent" > <h1>loading....</h1> </div>  )
                : 
                (
                  <>
                    <section className="section_title"> 
                      <span> <b>Exposiciones </b>({exhbitions.length} activas) </span> 
                    </section>
                    <div className="section_body">
                      { exhbitions.map((item) => ( <ExhibitionItem key={item.id} item = {item} /> ) ) }
                    </div>
                  </>
                )
              }
            </Route>
            <Route path='/exhibition/:id'>
              <ExhibitionTour exhibitions = {exhbitions}/>
            </Route>
            <Route path="" >
              <NoFoundPage/>
            </Route>
          </Switch>
      
      {/* </section> */}

      </BrowserRouter>
    </div>

  
  );
}

export default App;
