import React from 'react';
import { Link } from 'react-router-dom';
import poster_default from './assets/poster_default.png';

export default function ExhibitionItem({ item, toggleItem}) {

    // request a weekday along with a long date
    let options = { year: 'numeric', month: 'short', day: 'numeric' };  
    const startDate = item.dateStart.toDate().toLocaleString('es-ES', options);
    const endDate   = item.dateEnd.toDate().toLocaleString(  'es-ES', options);

    function handleItemClick(){
        toggleItem(item.id);
    }

    return (
        <div className="exhibitionCard">
            <Link to={`/exhibition/${item.id}`}> 
                <div className="exhibitionCard_Info">
                    <img src={ item.poster? item.poster : poster_default}  alt={`poster para la exposición ${item.title}`}/>
                    <div className="overlay">
                        <h3>Descripción</h3>
                        <p>
                            {item.description}
                        </p>
                    </div>
                </div>
                <div > 
                    <div style={{'paddingTop':  '5px', 'paddingBottom':  '5px', 'background':  '#cecece','display':  'flow-root',}} >
                        <span style={{'marginLeft':  '10px', 'float': 'left', 'fontSize': '14px'}}> <b>Desde: </b> { startDate } </span> 
                        <span style={{'marginRight': '10px', 'float': 'right', 'fontSize': '14px'}}> <b>Hasta: </b> { endDate } </span>
                    </div>
                    <h2>{item.title}</h2>
                </div>
            </Link>
        </div>
    )
}
