
import React, {useState, useRef, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import {Button, Breadcrumbs, Typography, Link, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PortraitIcon from '@material-ui/icons/Portrait';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';


/**
 * 
 * @param {*} props 
 * @param {*} exhibitions 
 * @returns 
 */
const  AppBreadcrumbs = (props ) => {

    // console.log(props);

    // Define Variables
    let [tour, setTour] = useState(null);
    const {
        history,
        location: { pathname },
        exhibitions, 
      } = props;
      const pathnames = pathname.split("/").filter(x => x);
      const isHome    = pathnames.length === 0 ? true : false;
      const id        = (pathnames.length > 0 ? pathnames[1] : null )  

    // Get the Exhibition Tour Data
    function getExhibitionTour(id){
        if(!exhibitions)
            return;
        const newExhibitions = [...exhibitions];
        const exhibit = newExhibitions.find(item => item.id === id);
        setTour(exhibit);
    }

    // Hook To initialize the Tour's data
    useEffect( () => {
        getExhibitionTour(id);
    }, [exhibitions, tour, id])


    // Define the Style for the breadcrumb 
    const useStyles = makeStyles((theme) => ({
        link: {
            display: 'flex'
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        Button:{
            backgroundColor: 'rgb(131,131,131)',
            right: 10,
            position: 'absolute'
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'left',
            height: 30,
            paddingLeft: 10,
        }
    }));
    const classes  = useStyles();

    // Go Back Callback 
    function goBack(event) {

        // Prevent this callback call Recursively 
        event.preventDefault();
        
        // Go back in browser history
        history.goBack();
    }


    // Component Return 
    return (

        <div className={classes.container}>
            { isHome ? 
              (
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                    <Typography color="inherit" className={classes.link}>
                        <AccountBalanceIcon className={classes.icon} />
                        Exposiciones
                    </Typography>
                </Breadcrumbs> 
              )
            : 
              ( 
                <>
                    <Breadcrumbs aria-label="breadcrumb" separator=">">

                        <Link color="inherit" href="/"  className={classes.link}>
                            <AccountBalanceIcon className={classes.icon} />
                            Exposiciones
                        </Link>

                        <Typography color="inherit" className={classes.link}>
                            <PortraitIcon className={classes.icon} />
                            { tour ? tour.title : 'Exposición ' + id}
                        </Typography>

                    </Breadcrumbs>
                    <Button type="button" size="small"  
                            variant="contained"  onClick={goBack}  
                            className={classes.Button}
                            startIcon={<KeyboardBackspaceIcon />}> 
                        atrás
                    </Button>  
                </>
              )
            }
        </div>

    ); // Enf of return

}; // End of component function

// Component Export
export default withRouter(AppBreadcrumbs);